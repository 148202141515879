import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { INoteInputs } from 'forms';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  defaultNoteValues,
  RelationshipNotesModalContext,
} from '../RelationshipNotesModalContext';

interface CloseIconButtonProps {
  onClick: () => void;
  'data-cy': string;
  label: string;
}

export function CloseIconButton({
  onClick,
  'data-cy': dataCy,
  label,
}: CloseIconButtonProps) {
  const { setOnConfirmDiscardNoteChanges, setIsDiscardNoteChangesModalOpen } =
    useContext(RelationshipNotesModalContext);
  const {
    reset,
    formState: { isDirty },
  } = useFormContext<INoteInputs>();

  const handleClick = (callback: () => void) => {
    if (isDirty) {
      setIsDiscardNoteChangesModalOpen(true);
      setOnConfirmDiscardNoteChanges(() => callback);
    } else {
      callback();
      reset(defaultNoteValues);
    }
  };

  return (
    <IconButton onClick={() => handleClick(onClick)}>
      <Close aria-label={label} data-cy={dataCy} />
    </IconButton>
  );
}
