import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import PolicyPlaceholder from './PolicyModalPlaceholder';

type PolicyProps = {
  isOpen: boolean;
  modalClose?: () => void;
  policyTitle?: string;
  policyContent?: React.ReactNode;
  buttonLabel?: string;
};

export const PolicyModal: React.FunctionComponent<PolicyProps> = ({
  isOpen,
  modalClose,
  policyTitle = 'Terms of Service',
  policyContent = <PolicyPlaceholder />,
  buttonLabel = 'Close',
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={modalClose}
      scroll="paper"
      aria-labelledby="policy-title"
      aria-describedby="policy-content"
    >
      <DialogTitle
        id="policy-title"
        color="text.primary"
        /* using the aria-level attribute here is bad practice. In general, the 
        level should be inferred from the heading level. However, in this 
        scenario, the MUI DialogTitle component renders a h2 tag, which 
        cannot be overridden.  */
        aria-level={1}
      >
        {policyTitle}
      </DialogTitle>
      <DialogContent id="policy-content" dividers tabIndex={0}>
        {policyContent}
      </DialogContent>
      <DialogActions>
        <Button
          data-cy="modals_PolicyModal_close-button"
          onClick={modalClose}
          variant="outlined"
          sx={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PolicyModal;
