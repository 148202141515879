import { Typography } from '@mui/material';

export const PolicyModalPlaceholder: React.FunctionComponent = () => {
  return (
    <>
      <Typography>
        This privacy policy applies between you, the User of this Website and
        Guider Global Limited, the owner and provider of this Website. Guider
        Global Limited takes the privacy of your information very seriously.
        This privacy policy applies to our use of any and all Data collected by
        us or provided by you in relation to your use of the Website.
      </Typography>
      <Typography sx={{ mt: 2, fontWeight: 700 }}>
        Please read this privacy policy carefully.
      </Typography>
      <Typography sx={{ my: 2 }}>
        1. In this privacy policy, the following definitions are used: Data
        collectively all information that you submit to Guider Global Limited
        via the Website. This definition incorporates, where applicable, the
        definitions provided in the Data Protection Laws; Cookies a small text
        file placed on your computer by this Website when you visit
        <br /> (i) employed by Guider Global Limited and acting in the course of
        their employment or
        <br /> (ii) engaged as a consultant or otherwise providing services to
        Guider Global Limited and accessing the Website in connection with the
        provision of such services; and Website the website that you are
        currently using, www.getguider.co, and any sub-domains of this site
        unless expressly excluded by their own terms and conditions.
        <br />
        <br /> 2. In this privacy policy, unless the context requires a
        different interpretation: <br />
        a. the singular includes the plural and vice versa;
        <br />
        <br /> b. references to sub-clauses, clauses, schedules or appendices
        are to sub-clauses, clauses, schedules or appendices of this privacy
        policy; <br />
        c. a reference to a person includes firms, companies, government
        entities, trusts and partnerships;
        <br />
        <br /> d. &quot;including&quot; is understood to mean &quot;including
        without limitation&quot;;
        <br />
        e. reference to any statutory provision includes any modification or
        amendment of it;
        <br />
        f. the headings and sub-headings do not form part of this privacy licy.
      </Typography>
    </>
  );
};

export default PolicyModalPlaceholder;
