import { SendBox, usePropsFor } from '@azure/communication-react';
import { useRelationships } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { IRelationship } from '@guider-global/shared-types';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Modal, Typography, useTheme } from '@mui/material';
import { Asterisk, FormTextField } from 'components';
import { useLocalization } from 'hooks';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { hexToRGB } from 'utils';

export interface IWelcomeMessageInputs {
  welcomeMessage: string;
}

export interface RelationshipGroupGuideModalProps {
  relationship: IRelationship | undefined;
  isModalOpen: boolean;
}

export const RelationshipGroupGuideModal = ({
  relationship,
  isModalOpen,
}: RelationshipGroupGuideModalProps) => {
  const theme = useTheme();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const sendBoxProps = usePropsFor(SendBox);

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(relationship?.programSlug ?? '');
  const programType = program?.program_details?.program_type;

  const title =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.group_created_successfully_modal?.title ?? '';

  const description =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.group_created_successfully_modal?.description ?? '';

  const inputTitle =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.group_created_successfully_modal?.input_title ?? '';

  const inputDescription =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.group_created_successfully_modal?.input_description ?? '';

  const buttonLabel =
    programType?.program_type_text.variations?.group?.relationships?.guide
      ?.group_created_successfully_modal?.post_message_button_label ?? '';

  const {
    reqRelationships,
    isLoadingRelationships,
    isMutatingRelationships,
    relationshipsRevalidate,
  } = useRelationships({});

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IWelcomeMessageInputs>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<IWelcomeMessageInputs> = async ({
    welcomeMessage,
  }) => {
    if (!relationship) {
      return;
    }

    await reqRelationships({
      method: 'PATCH',
      url: `/relationships/${relationship?.id}`,
      data: {
        group: {
          welcomeMessage,
        },
      },
    });

    await sendBoxProps.onSendMessage(welcomeMessage);
    relationshipsRevalidate();
  };

  return (
    <Modal open={isModalOpen} data-cy="group-guide-welcome-message-modal">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            width: '530px',
            maxWidth: '95%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            py: 6,
            px: 3,
            pb: 3,
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              top: '-80px',
              left: '-15%',
              position: 'absolute',
              width: '130%',
              minHeight: '300px',
              borderRadius: '100%',
              backgroundColor: `rgba(${hexToRGB(
                theme.palette.info.main,
              )}, 0.08)`,
            }}
          />
          <Box
            sx={{
              borderRadius: '50%',
              backgroundColor: `rgba(${hexToRGB(
                theme.palette.success.main,
              )}, 0.08)`,
              width: '82px',
              height: '82px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                borderRadius: '50%',
                backgroundColor: `rgba(${hexToRGB(
                  theme.palette.success.main,
                )}, 0.08)`,
                width: '66px',
                height: '66px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2,
              }}
            >
              <CheckCircle color="success" fontSize="large" />
            </Box>
          </Box>

          <Typography
            variant="h4"
            component="p"
            sx={{ mb: 0.5, mt: 2, textAlign: 'center' }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: 'center', mt: 5, mb: 4 }}
          >
            {description}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 3,
              mb: 4.5,
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'start',
                position: 'relative',
                boxSizing: 'border-box',
              }}
            >
              <Typography variant="h6" sx={{ mb: 0.5, textAlign: 'center' }}>
                {inputTitle}
                <Asterisk />
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {inputDescription}
              </Typography>
              <FormTextField
                control={control as unknown as Control}
                name={'welcomeMessage'}
                label={''}
                errors={errors}
                required
                rows={4}
              />
            </Box>
          </Box>
          <LoadingButton
            data-cy="group-guide-post-welcome-message-button"
            variant="contained"
            color="info"
            size="large"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
            loading={isLoadingRelationships || isMutatingRelationships}
          >
            {buttonLabel}
          </LoadingButton>
        </Card>
      </Box>
    </Modal>
  );
};
