// external
import React from 'react';
import {
  Box,
  Card,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { ActionButton } from 'components';

//types
import { IButtonAction } from 'components/ActionButton';

import { useMobileMediaQuery } from 'hooks';

interface IConfirmationModalProps {
  open: boolean;
  title: string | undefined;
  description: string | undefined;
  actions: IButtonAction[];
  handleClose: () => void;
  isLoading?: boolean;
  'data-cy'?: string;
  closeIconButtonDataCy?: string;
}

export const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  open,
  title,
  description,
  actions,
  handleClose,
  isLoading = false,
  'data-cy': dataCy,
  closeIconButtonDataCy,
}) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxHeight: isMobile ? '100vh' : '90vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            width: isMobile ? '90vw' : '600px',
            borderTop: `8px solid ${theme.palette.secondary.main}`,
            '*': {
              boxSizing: 'border-box',
            },
          }}
          data-cy={dataCy}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              borderBottom: `1px solid ${theme.palette.divider}`,
              py: 2,
              px: 3,
            }}
          >
            <Typography variant="h6" fontSize={'20px'}>
              {title}
            </Typography>
            <IconButton
              onClick={handleClose}
              disabled={isLoading}
              sx={{ px: 0, py: 0 }}
            >
              <Close data-cy={closeIconButtonDataCy} />
            </IconButton>
          </Box>
          <Box sx={{ width: '100%', py: 2, px: 3 }}>
            <Typography
              sx={{ fontWeight: 400 }}
              variant="body1"
              fontSize={'16px'}
            >
              {description}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              px: 3,
              py: 2,
              backgroundColor: '#f5f5f5',
            }}
          >
            {actions.map((action, index) => (
              <ActionButton
                isLoading={isLoading}
                buttonAction={action}
                key={`modal-button-${index}`}
              />
            ))}
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};
