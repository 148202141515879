// external
import React, { useMemo } from 'react';
import { Modal } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

// components
import {
  RelationshipGoalsManage,
  RelationshipGoalsView,
  RelationshipGoalsCreate,
  RelationshipGoalsEdit,
} from 'components';

// types
import { IRelationship } from '@guider-global/shared-types';

interface IRelationshipGoalsModalProps {
  relationship: IRelationship;
  isOpen: boolean;
  handleClose: () => void;
  isGuide: boolean;
}

export const RelationshipGoalsModal: React.FC<IRelationshipGoalsModalProps> = ({
  relationship,
  isOpen,
  handleClose,
  isGuide,
}) => {
  const { goalId } = useParams();
  const { pathname } = useLocation();

  const isGoalCreation = useMemo(() => {
    return pathname.includes('/create');
  }, [pathname]);

  const isGoalEdit = useMemo(() => {
    return pathname.includes('/edit');
  }, [pathname]);

  const renderContent = () => {
    if (isGoalEdit) {
      return (
        <RelationshipGoalsEdit
          relationship={relationship}
          handleClose={handleClose}
        />
      );
    }

    if (isGoalCreation) {
      return (
        <RelationshipGoalsCreate
          relationship={relationship}
          handleClose={handleClose}
        />
      );
    }

    if (goalId) {
      return (
        <RelationshipGoalsView
          relationship={relationship}
          handleClose={handleClose}
          isGuide={isGuide}
        />
      );
    }

    return (
      <RelationshipGoalsManage
        relationship={relationship}
        handleClose={handleClose}
      />
    );
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      {renderContent()}
    </Modal>
  );
};
