import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { IButtonAction } from 'components/ActionButton';
import { INoteInputs } from 'forms';
import { useLocalization } from 'hooks';
import { ConfirmationModal } from 'modals/ConfirmationModal';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  defaultNoteValues,
  RelationshipNotesModalContext,
} from '../RelationshipNotesModalContext';

export function RelationshipDiscardNoteChangesModal() {
  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const discard_unsaved_changes_modal =
    baseLanguage?.relationships?.notes?.notes_modal
      ?.notes_modal_create_edit_note?.discard_unsaved_changes_modal;

  const {
    isDiscardNoteChangesModalOpen,
    setIsDiscardNoteChangesModalOpen,
    onConfirmDiscardNoteChanges,
    setOnConfirmDiscardNoteChanges,
  } = useContext(RelationshipNotesModalContext);
  const { reset } = useFormContext<INoteInputs>();

  const resetDiscardNoteChangesModal = () => {
    setOnConfirmDiscardNoteChanges(undefined);
    setIsDiscardNoteChangesModalOpen(false);
  };

  const actions: IButtonAction[] = [
    {
      label: baseLanguage?.globals?.common?.discard_changes_button_label,
      variant: 'contained',
      color: 'error',
      action: () => {
        onConfirmDiscardNoteChanges?.();
        reset(defaultNoteValues);
        setIsDiscardNoteChangesModalOpen(false);
      },
    },
    {
      label: discard_unsaved_changes_modal?.continue_editing_button_label,
      variant: 'contained',
      color: 'info',
      action: resetDiscardNoteChangesModal,
    },
  ];

  return (
    <ConfirmationModal
      open={isDiscardNoteChangesModalOpen}
      title={discard_unsaved_changes_modal?.title}
      description={discard_unsaved_changes_modal?.description}
      actions={actions}
      handleClose={resetDiscardNoteChangesModal}
    />
  );
}
