import {
  Avatar,
  Box,
  Button,
  Card,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { toggleModal } from 'store/slices/appSlice';
import { hexToRGB } from 'utils';

export const RelationshipGroupTraineeModal = ({
  isModalOpen,
  title,
  description,
  buttonLabel,
  picture,
}: {
  isModalOpen: boolean;
  title: string;
  description: string;
  buttonLabel: string;
  picture: string;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  return (
    <Modal open={isModalOpen} data-cy="group-trainee-welcome-message-modal">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            width: '530px',
            minHeight: '380px',
            maxwidth: '95%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            py: 6,
            px: 3,
            boxSizing: 'border-box',
          }}
        >
          <Box
            sx={{
              top: '-60px',
              left: '-15%',
              position: 'absolute',
              width: '130%',
              minHeight: '300px',
              borderRadius: '100%',
              backgroundColor: `rgba(${hexToRGB(
                theme.palette.info.main,
              )}, 0.08)`,
            }}
          />
          <Typography
            variant="h4"
            component="p"
            sx={{ mb: 0.5, textAlign: 'center' }}
          >
            {title}
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {description}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              my: 3,
              mb: 4.5,
              zIndex: 2,
            }}
          >
            <Card
              sx={{ backgroundColor: '#FFFFFF', p: 0.25, ml: -1, zIndex: 1 }}
            >
              <Avatar
                src={picture}
                sx={{ width: '112px', height: '112px' }}
                variant="rounded"
              />
            </Card>
          </Box>
          <Button
            data-cy="group-trainee-view-relationship-button"
            variant="contained"
            color="info"
            size="large"
            onClick={() => dispatch(toggleModal('traineeModal'))}
          >
            {buttonLabel}
          </Button>
        </Card>
      </Box>
    </Modal>
  );
};
