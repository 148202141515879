import { FC, PropsWithChildren } from 'react';

import { Box, Modal, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ModalCard } from 'components/ModalCard';
import { IButtonAction } from 'components/ActionButton';

export interface RegistrationLimitReachedModalProps {
  open: boolean;
  title: string;
  description?: string;
  content: string;
  action: IButtonAction;
}

export const RegistrationLimitReachedModal: FC<
  PropsWithChildren<RegistrationLimitReachedModalProps>
> = ({ open, title, description, content, action }) => {
  return (
    <Modal open={open}>
      <ModalCard
        title={
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row nowrap',
              gap: 1,
              alignItems: 'center',
            }}
          >
            <ErrorOutlineIcon color="warning" />
            {title}
          </Box>
        }
        description={description}
        headerLineColor="warning"
        actions={[action]}
      >
        <Typography
          variant="body1"
          color="text.primary"
          sx={{ whiteSpace: 'pre-wrap' }}
        >
          {content}
        </Typography>
      </ModalCard>
    </Modal>
  );
};
