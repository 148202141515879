import Group from '@mui/icons-material/Group';
import { Modal, useTheme } from '@mui/material';
import { ModalCard } from 'components';
import { IButtonAction } from 'components/ActionButton';

export const RelationshipGroupMembersModal = ({
  isModalOpen,
  title,
  children,
  handleClose,
  actions,
}: {
  isModalOpen: boolean;
  actions?: (IButtonAction | IButtonAction[])[];
  title: string;
  children: React.ReactNode;
  handleClose: () => void;
}) => {
  const theme = useTheme();
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <ModalCard
        width={'447px'}
        height="65vh"
        title={title}
        actions={actions}
        icon={<Group sx={{ color: theme.palette.secondary.main }} />}
      >
        {children}
      </ModalCard>
    </Modal>
  );
};
