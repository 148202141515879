//external
import {
  Box,
  Card,
  Divider,
  LinearProgress,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

//types
import { IRelationship } from '@guider-global/shared-types';

//components
import { Transition } from '@guider-global/ui';
import {
  RelationshipNote,
  RelationshipNotesEmpty,
  RelationshipNotesList,
} from 'components';
import { CloseIconButton } from './CloseIconButton';

//hooks
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { useLocalization, useMobileMediaQuery, useNotes } from 'hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface IRelationshipNotesModalProps {
  relationship: IRelationship;
  isOpen: boolean;
  handleClose: () => void;
}

export const RelationshipNotesModal: React.FC<IRelationshipNotesModalProps> = ({
  relationship,
  isOpen,
  handleClose,
}) => {
  //hooks
  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  //style
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const [isListOpen, setIsListOpen] = useState<boolean>(!isMobile);

  const navigate = useNavigate();
  const { noteId, relationshipId } = useParams();
  const { pathname } = useLocation();

  const {
    isLoadingNotes,
    isValidatingNotes,
    isMutatingNotes,
    notes = [],
  } = useNotes({
    params: {
      relationshipId: relationshipId,
    },
  });
  const isLoading = isLoadingNotes || isValidatingNotes || isMutatingNotes;
  const noNotes = notes.length === 0;

  const isNoteCreation = useMemo(() => {
    return pathname.includes('/create');
  }, [pathname]);

  useEffect(() => {
    if (isOpen && !isMobile && !isNoteCreation && !noNotes && !noteId) {
      navigate(`/relationships/${relationshipId}/notes/${notes?.at(0)?.id}`);
    }
  });

  //component
  return (
    <Modal open={isOpen} onClose={handleClose} disableEscapeKeyDown>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: { xs: '100vh', md: '75vh' },
          minHeight: { xs: '100vh', md: '75vh' },
          width: { xs: '100vw', md: '80vw' },
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderTop: `8px solid ${theme.palette.secondary.main}`,
          ...(isMobile && { borderRadius: '0px' }),
          overflowY: 'auto',
        }}
        data-cy="relationship-notes-modal"
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: 2, md: 3 },
            py: 2,
          }}
        >
          <Typography
            variant="h6"
            component="p"
            data-cy="relatioship-notes-title"
          >
            {
              baseLanguage?.relationships?.notes?.notes_modal?.notes_common
                ?.notes_common_title
            }
          </Typography>
          <CloseIconButton
            onClick={handleClose}
            data-cy="relationship-notes-modal-close-icon-button"
            label={baseLanguage?.globals?.common?.close_button_label ?? 'Close'}
          />
        </Box>
        {isLoading ? (
          <LinearProgress color="secondary" variant="query" />
        ) : (
          <Box pt={0.5} />
        )}
        <Divider />

        <Box sx={{ display: 'flex', flex: 1 }}>
          <Transition
            open={isListOpen}
            transitionStyles={{
              entering: {
                flexGrow: 1,
                maxWidth: { xs: '100%', md: '30%' },
              },
              entered: {
                flexGrow: 1,
                maxWidth: { xs: '100%', md: '30%' },
              },
              exiting: {
                width: { xs: '40px', md: '60px' },
              },
              exited: {
                width: { xs: '40px', md: '60px' },
              },
            }}
            defaultStyles={{
              transition: `width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
              borderRight: '1px solid rgba(0, 0, 0, 0.12)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'stretch',
              height: '100%',
            }}
            data-cy="relationship-notes-list-container"
          >
            <RelationshipNotesList
              notes={notes}
              isExpanded={isListOpen}
              handleExpand={(expand) =>
                setIsListOpen((isOpen) => expand ?? !isOpen)
              }
            />
          </Transition>
          <Transition
            data-cy="relationship-notes-modal-note-form"
            open={isMobile ? !isListOpen : true}
            defaultStyles={{
              transition: `width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: 2,
              pt: 0,
            }}
            transitionStyles={{
              entering: {
                flexGrow: 3,
              },
              entered: {
                flexGrow: 3,
              },
              exiting: {
                flexGrow: { xs: 0, md: 3 },
                width: { xs: 0, md: '100%' },
              },
              exited: {
                flexGrow: { xs: 0, md: 3 },
                width: { xs: 0, md: '100%' },
                display: { xs: 'none', md: 'block' },
              },
            }}
          >
            {noNotes && !isNoteCreation ? (
              <RelationshipNotesEmpty />
            ) : (
              <RelationshipNote relationship={relationship} />
            )}
          </Transition>
        </Box>
      </Card>
    </Modal>
  );
};
