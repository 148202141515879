import { emptyInitialValue } from 'components/FormRichText';
import { INoteInputs, NoteFormField } from 'forms';
import { useNotes } from 'hooks';
import React, {
  createContext,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

interface IRelationshipNotesModalContext {
  isDiscardNoteChangesModalOpen: boolean;
  setIsDiscardNoteChangesModalOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | (() => never);
  onConfirmDiscardNoteChanges: (() => void) | undefined;
  setOnConfirmDiscardNoteChanges:
    | React.Dispatch<React.SetStateAction<(() => void) | undefined>>
    | (() => never);
}

export const RelationshipNotesModalContext =
  createContext<IRelationshipNotesModalContext>({
    isDiscardNoteChangesModalOpen: false,
    setIsDiscardNoteChangesModalOpen: () => {
      throw new Error('setIsDiscardNoteChangesModalOpen is not implemented');
    },
    onConfirmDiscardNoteChanges: () => {
      throw new Error('onConfirmDiscardNoteChanges is not implemented');
    },
    setOnConfirmDiscardNoteChanges: () => {
      throw new Error('setOnConfirmDiscardNoteChanges is not implemented');
    },
  });

export const defaultNoteValues = {
  [NoteFormField.Title]: '',
  [NoteFormField.Content]: emptyInitialValue,
  [NoteFormField.IsPublic]: false,
};

export const RelationshipNotesModalProvider = ({
  children,
  relationshipId,
}: PropsWithChildren<{ relationshipId: string }>) => {
  const [isDiscardNoteChangesModalOpen, setIsDiscardNoteChangesModalOpen] =
    useState(false);
  const [onConfirmDiscardNoteChanges, setOnConfirmDiscardNoteChanges] =
    useState<() => void>();

  const { noteId } = useParams();
  const { notes = [] } = useNotes({
    params: {
      relationshipId: relationshipId,
    },
  });

  const currentNote = useMemo(
    () => notes?.find((note) => note.id === noteId),
    [notes, noteId],
  );
  const isPublic =
    typeof currentNote?.isPrivate === 'undefined'
      ? false
      : !currentNote?.isPrivate;

  const methods = useForm<INoteInputs>({
    mode: 'onChange',
    defaultValues: defaultNoteValues,
    values: {
      [NoteFormField.Title]: currentNote?.title,
      [NoteFormField.Content]: currentNote?.content,
      [NoteFormField.IsPublic]: isPublic,
    },
  });

  const value: IRelationshipNotesModalContext = {
    isDiscardNoteChangesModalOpen,
    setIsDiscardNoteChangesModalOpen,
    onConfirmDiscardNoteChanges,
    setOnConfirmDiscardNoteChanges,
  };

  return (
    <FormProvider {...methods}>
      <RelationshipNotesModalContext.Provider value={value}>
        {children}
      </RelationshipNotesModalContext.Provider>
    </FormProvider>
  );
};
